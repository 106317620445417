'use strict'

/**
 * Controller for the product page
 * @param $window
 * @param $scope
 */
function productController ($window, $scope) {
  'ngInject'
  /* jshint validthis:true */

  const body = angular.element(document.getElementsByTagName('body'))
  const landingColorBlock1 = document.getElementsByClassName('landing-color-block-1')
  const productExam = document.getElementsByClassName('product-exam')
  const landingColorBlock2 = document.getElementsByClassName('landing-color-block-2')
  const customImages = document.getElementsByClassName('custom-image')
  const customHeading = document.getElementsByClassName('custom-heading')
  const customText = document.getElementsByClassName('custom-text')
  const landingFooter = document.getElementsByClassName('landing-footer')

  angular.element(body[0]).on('scroll', () => {
    if (isVisible(landingColorBlock1)) {
      attachSlideIn(landingColorBlock1[0])
    }
    if (isVisible(productExam)) {
      attachSlideIn(productExam[0])
    }
    if (isVisible(landingColorBlock2)) {
      attachSlideIn(landingColorBlock2[0])
    }
    if (isVisible(customHeading)) {
      attachSlideIn(customHeading[0])
    }
    if (isVisible(customText)) {
      attachSlideIn(customText[0])
    }
    if (isVisible(customImages)) {
      attachSlideIn(customImages[0])
      attachSlideIn(customImages[1])
    }
    if (isVisible(landingFooter)) {
      attachSlideIn(landingFooter[0])
    }
  })

  $scope.$on('$destroy', () => {
    angular.element(body[0]).off('scroll')
  })

  /**
   * attaches the slide in class to the element
   * @param element
   */
  function attachSlideIn (element) {
    angular.element(element).addClass('landing__instant-slide-in')
  }

  /**
   * checks if the element is visible on the page
   * @param element
   * @returns {boolean|boolean}
   */
  function isVisible (element) {
    return element[0].getBoundingClientRect().top <= $window.innerHeight &&
      !element[0].classList.contains('landing__instant-slide-in')
  }
}

export { productController }
