'use strict'

function builderMetaDataDialog (mdDialog, links, linkIdx, typeOfMedia, existingMetadata, BuilderService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this
  vm.close = close
  vm.sendMetadata = sendMetadata

  vm.metaData = existingMetadata
  vm.uploadError = false

  function sendMetadata() {
    if (!links[linkIdx].metaData) {
      links[linkIdx].metaData = insertMissingMetaData()
    }

    if (typeOfMedia === 'video') {
      BuilderService.addMetaData({
        metadataId: links[linkIdx].metaData.meta_id,
        videoId: links[linkIdx].id,
        date: vm.metaData.date,
        time: vm.metaData.time,
        type: vm.metaData.type,
        orientation: vm.metaData.orientation
      }).then((data) => {
        vm.uploadError = false
        links[linkIdx].metaData.meta_id =  data.insertId
        mdDialog.hide()
      }, (err) => {
        vm.uploadError = true
        console.error(err)
      })
    } else {
      console.log('links === ', links)
      BuilderService.addMetaData({
        metadataId: links[linkIdx].metaData.meta_id,
        imageId: links[linkIdx].imageId,
        date: vm.metaData.date,
        time: vm.metaData.time,
        type: vm.metaData.type,
        orientation: vm.metaData.orientation
      }).then((data) => {
        vm.uploadError = false
        links[linkIdx].metaData.meta_id =  data.insertId
        mdDialog.hide()
      }, (err) => {
        vm.uploadError = true
        console.error(err)
      })
    }
  }

  function close () {
    mdDialog.hide()
  }

  function insertMissingMetaData () {
    return {
      date: null,
      meta_id: null,
      orientation: null,
      time: null,
      type: null
    }
  }
}


export { builderMetaDataDialog }
