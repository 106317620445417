'use strict'
/* global localStorage */
/**
 * mcqs-list-filter directive
 * @type {{templateUrl: string, scope: {}, bindings: {list: string, isLoaded: string, listType: string, selectedCategory: string, searchText: string}, controller: utilsListFilterController, controllerAs: string}}
 */
const mcqsListFilter = {
  templateUrl: 'partials/templates/utils/utils_list_filter.html',
  scope: {},
  bindings: {
    list: '=',
    isLoaded: '@',
    listType: '@',
    selectedCategory: '=?',
    searchText: '=?'
  },
  controller: utilsListFilterController,
  controllerAs: 'vm'
}

/**
 * This is used for filtering, sorting, and searching the builders question list
 * and exam select list
 * @param $filter
 * @param $scope
 * @param $timeout
 */
function utilsListFilterController ($filter, $scope, $timeout) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  let filterObj = {}
  let listWatch

  console.log('vm.listType == ', vm.listType)

  vm.searchText = ''
  // this is a collection of filters currently supported in the builder question dialog and exam select dialog
  if (vm.listType === 'question') {
    vm.filters = [
      {title: 'Date created', filter: 'dateCreated', sort: false},
      {title: 'Date modified', filter: 'dateModified', sort: false},
      {title: 'Title', filter: 'title', sort: false},
      {title: 'Published', filter: 'isPublished', sort: false},
      {title: 'Total attempts', filter: 'attempts', sort: false},
      {title: 'Correct attempts', filter: 'corrects', sort: false},
      {title: 'Likes', filter: 'likes', sort: false},
      {title: 'Dislikes', filter: 'dislikes', sort: false},
      {title: 'Difficulty', filter: 'score', sort: false}
    ]
  } else if (vm.listType === 'unlinked puzzle') {
    vm.filters = [
      {title: 'Date created', filter: 'dateCreated', sort: false},
      {title: 'Date modified', filter: 'dateModified', sort: false},
      {title: 'Title', filter: 'title', sort: false}
    ]
  } else if (vm.listType === 'patient') {
    vm.filters = [
      {title: 'Date of Birth', filter: 'dob', sort: false},
      {title: 'MRN', filter: 'mrn', sort: false},
      {title: 'Sex', filter: 'sex', sort: false},
      {title: 'Name', filter: 'name', sort: false}
    ]
  } else {
    vm.filters = [
      {title: 'Name', filter: 'examName', sort: false}
    ]
  }

  vm.sort = sort
  vm.changeSorting = changeSorting

  // angular hook that is called the controller is destroyed
  vm.$onDestroy = () => {
    // call the watcher to destroy it
    listWatch()
  }

  activate()

  /**
   * This has to handle cases where some data has changed or not loaded yet
   */
  function activate () {
    if (vm.isLoaded === true) init(vm.listType)
    if (vm.listType === 'question' || vm.listType === 'unlinked puzzle') {
      // create a watcher to watch vm.isLoaded
      listWatch = $scope.$watch(
        () => vm.isLoaded,
        newVal => {
          // if vm.isLoaded is undefined do nothing
          if (newVal !== undefined) {
            // if it is defined trigger init() and execute a digest cycle
            $timeout(init(vm.listType))
          }
        }
      )
    } else if (vm.listType === 'patient') {
      $timeout(init(vm.listType), 100)
    } else {
      // create a watcher to watch vm.selectedCategory
      listWatch = $scope.$watch(
        () => vm.selectedCategory,
        newVal => {
          // if vm.selectedCategory is undefined do nothing
          if (newVal !== undefined) {
            // if it is defined trigger init() and execute a digest cycle
            $timeout(init(vm.listType))
          }
        }
      )
    }
  }

  /**
   * initialisation function that applies a filter each tme vm.isLoaded or
   * vm.selectedCategory updates
   * @param listType
   */
  function init (listType) {
    console.log('initing')
    vm.searchText = ''
    // if listType does not exist in localStorage create it there
    if (!localStorage[listType]) localStorage[listType] = JSON.stringify(filterObj)
    // make a copy of the original list before sorting
    console.log('vm.list === ', vm.list)
    vm.originalList = angular.copy(vm.list)
    // create default filter
    const {mcqsFilter = '0', mcqsFilterSorting = false} = JSON.parse(localStorage[listType])
    // set currently selected filter
    console.log('vm.filter === ', vm.filters)
    vm.selectedFilter = vm.filters[Number(mcqsFilter)]
    vm.selectedFilter.sort = mcqsFilterSorting
    // trigger sort() and fire off digest cycle
    $timeout(sort, 100)
  }

  /**
   * sorts vm.list
   */
  function sort () {
    // if there is no original list make a copy of one now
    if (!vm.originalList || vm.originalList.length < 1) vm.originalList = angular.copy(vm.list)

    let {filter, sort} = vm.selectedFilter
    // set current filter
    filterObj.mcqsFilter = vm.filters.indexOf(vm.selectedFilter)
    // set current sorting
    filterObj.mcqsFilterSorting = vm.selectedFilter.sort
    // update listType in localStorage to match the new filterObj
    localStorage[vm.listType] = JSON.stringify(filterObj)
    // filter list by search term
    console.log('vm.originalList === ', vm.originalList)
    vm.list = vm.originalList.filter(searchKeys)
    // filter by selected filter and sort type
    vm.list = $filter('orderBy')(vm.list, filter, sort)
    // filter out any elements of the list that have no title
    vm.list = $filter('orderBy')(vm.list, (value) => { if (value.title) return value.title.length <= 0 })
  }

  /**
   * This is the function that searches the an objects keys for the current search text
   * @param item
   * @returns {boolean}
   */
  function searchKeys (item) {
    console.log('searchomhs === ', item)
    let searchWords = vm.searchText.split(/\s+/)
    let regPart = ''
    let arrayBounds = searchWords.length
    for (let i = 0; i < arrayBounds; i++) {
      // looks for any special characters and replaces them with $&. $& returns the entire string if matched
      searchWords[i] = searchWords[i].replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
      // creates a new regex which uses positive lookahead to match the search words and
      // any other text. This will be appended and passed to the RegExp constructor
      regPart += '(?=' + searchWords[i] + ')(.*)'
    }
    let regex = new RegExp('^(.*)' + regPart + '$', 'i')
    // let regexDate = new RegExp('(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.00Z')
    let match = false
    // loop through each item and return true for matches. They will then be added
    // to the vm.list in the sort() function
    angular.forEach(item, function (key) {
      if (!match) {
        if (key !== null) {
          if (typeof key === 'object') {
            key = key.toLocaleString('en-GB').substring(0, 10)
          }
          if (regex.test(key.toString())) {
            match = true
          }
        }
      }
    })
    return match
  }

  /**
   * flips the currently selected sortBy filter
   */
  function changeSorting () {
    vm.selectedFilter.sort = !vm.selectedFilter.sort
    vm.sort()
  }
}

export { mcqsListFilter }
