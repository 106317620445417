'use strict'
import {builderMetaDataDialog} from "./builder_meta_data_dialog";

/**
 * mcqs-builder-media-links directive
 * @type {{templateUrl: string, bindings: {links: string}, controller: builderMediaLinksController, controllerAs: string}}
 */
const mcqsBuilderMediaLinks = {
  templateUrl: 'partials/templates/builder/builder_media_links.html',
  bindings: {
    links: '=',
    questionId: '@'
  },
  controller: builderMediaLinksController,
  controllerAs: 'vm'
}

/**
 * builderMediaLinksController handles the validation and uploading of vimeo, soundcloud
 * and youtube links
 * @param $http
 * @param BuilderService
 * @param BuilderHandler
 * @param LottieService
 * @param YOUTUBE_API_KEY key for accessing youtube API
 */
function builderMediaLinksController ($http, Upload, DOMAIN, $mdDialog, BuilderService, BuilderHandler, LottieService, YOUTUBE_API_KEY, $scope) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.newLink = ''
  vm.currentAudioText = []
  vm.uploading = false

  vm.addLink = addLink
  vm.deleteLink = deleteLink
  vm.uploadAudioDropDown = uploadAudioDropDown
  vm.addAudio = addAudio
  vm.audioInputStyle = audioInputStyle
  vm.disableUploadButton = disableUploadButton
  vm.showAudioButton = showAudioButton
  vm.disableRemoveAudioButton = disableRemoveAudioButton
  vm.removeAudio = removeAudio
  vm.addMetaData = addMetaData
  vm.addLottieFileDirectly = addLottieFileDirectly

  let numberOfRequests

  $scope.$watch('vm.links', () => {
    for (const link of vm.links) {
      vm.currentAudioText[vm.links.indexOf(link)] =
        vm.links[vm.links.indexOf(link)].audioText
    }
  })

  /**
   * handles checking regex fro media links and calls the appropriate function
   * to add the media link
   * @returns {*}
   */
  function addLink () {
    // if vm.links does not exist create it
    if (!vm.links) vm.links = []
    if (vm.links.length >= 5) {
      return vm.mediaLinkForm.link.$setValidity('linkLimit', false)
    }
    // these regexs are used to decipher which type of link is being added
    const YOUTUBE_FULL = vm.newLink.match(/watch\?v=((\w|-){11})/g)
    const YOUTUBE_SHORT = vm.newLink.match(/\.be\/((\w|-){11})/g)
    const VIMEO = vm.newLink.match(/vimeo\.com\/((\w|-){9})/g)
    const SOUNDCLOUD = vm.newLink.match(/soundcloud\.com\/(.*\/)(.*)$/g)
    const LOTTIE = vm.newLink.match(/^https:\/\/lottie\.host\/[\w-]{36}\/([\w-]{10,20})\.json$/)

    let id = ''
    // check which kind of media is being uploaded and strip the media id out.
    if (YOUTUBE_FULL && YOUTUBE_FULL.length > 0) id = YOUTUBE_FULL[0].slice(8)
    else if (YOUTUBE_SHORT && YOUTUBE_SHORT.length > 0) id = YOUTUBE_SHORT[0].slice(4)
    else if (VIMEO && VIMEO.length > 0) id = VIMEO[0].slice(10)
    else if (SOUNDCLOUD && SOUNDCLOUD.length > 0) id = SOUNDCLOUD[0].slice(15)
    else if (LOTTIE && LOTTIE.length > 0) id = LOTTIE[1]
    else return updateNgMessages('invalidLink')

    // check if links already exist
    vm.links.forEach((val) => {
      if (val.url.indexOf(id) >= 0) updateNgMessages('linkAlreadyExists')
    })

    if (YOUTUBE_FULL || YOUTUBE_SHORT) {
      uploadYouTubeLink(id)
    } else if (VIMEO) {
      uploadVimeoLink(id)
    } else if (SOUNDCLOUD) {
      // Soundcloud has no validation because we never got an api key from them.
      // We just check if the link is valid
      const url = `![SOUNDCLOUD](${id})`
      const img = 'https://developers.soundcloud.com/assets/logo_big_black-4fbe88aa0bf28767bbfc65a08c828c76.png'
      pushAndSave(img, url)
    } else if (LOTTIE) {
      uploadLottieFile(id)
    }
  }

  /**
   * request video from youtube and then call a function to save the image and url
   * @param id string id for youtube video
   */
  function uploadYouTubeLink (id) {
    $http.get(`https://www.googleapis.com/youtube/v3/videos?part=id&id=${id}&key=${process.env.YOUTUBE_API_KEY}`, {
      Authorization: `Bearer ${process.env.YOUTUBE_API_KEY}`,
      Accept: 'application/json'
    }).then(
      response => {
        if (response.data.items.length > 0) {
          const img = `https://img.youtube.com/vi/${id}/hqdefault.jpg`
          const url = `![YOUTUBE](${id})`
          pushAndSave(img, url)
        } else {
          return failure(response.status, 'YouTube')
        }
      },
      error => failure(error, 'YouTube')
    )
  }
  /**
   * request video from vimeo and then call a function to save the image and url
   * @param id string id for vimeo video
   */
  function uploadVimeoLink (id) {
    $http.get(`https://vimeo.com/api/v2/video/${id}.json`).then(
      response => {
        if (response.status === 200) {
          const img = response.data[0].thumbnail_large || ''
          const url = `![VIMEO](${id})`
          pushAndSave(img, url)
        } else {
          return failure(response.status, 'Vimeo')
        }
      },
      error => {
        failure(error, 'Vimeo')
      }
    )
  }

  /**
   * passes the path to the lottie file which in turn passes it
   * to node to be saved
   * @param id - link to file on lottie site
   */
  function uploadLottieFile (id) {
    LottieService.uploadJSON(id, vm.newLink).then((response) => {
      if (response.status === 200) {
        const url = `![LOTTIE](${response.data.filename})`
        const img = response.data.animationName
        pushAndSave(img, url)
      } else {
        return failure(response.status, 'Lottie')
      }
    }, (error) => {
      failure(error, 'Lottie')
    })
  }

  function uploadAudioDropDown (index) {
    if (vm.links[index].show) {
      vm.links[index].show = !vm.links[index].show
    } else {
      vm.links[index].show = true
    }
  }

  function audioInputStyle (index) {
    if (vm.links[index].show) {
      return {}
    } else {
      return {'max-height': '48px'}
    }
  }

  function addAudio (index) {
    BuilderHandler.disableAudioUpload = true
    BuilderService.addAudio(vm.currentAudioText[index], vm.links[index].id,
      BuilderHandler.question.questionId).then(() => {
      BuilderHandler.links[index].audioText = vm.currentAudioText[index]
      BuilderHandler.disableAudioUpload = false
      vm.links[index].show = false
    }, (err) => {
      if (err) console.error('Error: ', err)
      BuilderHandler.disableAudioUpload = false
    })
  }

  function addLottieFileDirectly (files, invalidFiles) {
    numberOfRequests = 0
    if (files && files.length) {
      // doesn't allow more than 10 images
      numberOfRequests = (vm.links.length + files.length > 10) ? 10 - vm.links.length : files.length
      vm.uploading = vm.links.length < 10
      for (let i = 0; i < files.length; i++) {
        if (vm.links.length + (i + 1) > 10) { break }
        Upload.upload({
          url: DOMAIN + '/api/builder/question/upload/lottie/' + vm.questionId,
          data: {file: files[i]}
        }).then(success, failure)
      }
    }
    if (invalidFiles && invalidFiles.length > 0) showError()

    function success (response) {
      const url = `![LOTTIE](${response.data.filename})`
      const img = response.data.animationName
      numberOfRequests--
      if (numberOfRequests <= 0) { vm.uploading = false }
      pushAndSave(img, url)
    }

    function failure (err) {
      console.log('failure')
      console.error('Lottie upload failed:', err)
      numberOfRequests--
      if (numberOfRequests <= 0) { vm.uploading = false }
      showError()
    }
  }

  function showError () {
    $mdDialog.show(
      $mdDialog.alert()
        .clickOutsideToClose(true)
        .title('Lottie upload failed')
        .disableParentScroll(false)
        .textContent('An error occurred while saving your lottie file. Please try again.')
        .ariaLabel('Lottie upload failure dialog')
        .ok('Okay')
    )
  }

  function removeAudio (index) {
    BuilderHandler.deleteInProgress = true
    BuilderService.removeAudio(BuilderHandler.question.questionId, vm.links[index].id).then(() => {
      vm.links[index].audioText = null
      vm.currentAudioText[index] = null
      BuilderHandler.deleteInProgress = false
    }, (err) => {
      BuilderHandler.deleteInProgress = false
      console.error('Error removing audio: ', err)
    })
  }

  function disableRemoveAudioButton (index) {
    return (vm.links[index].audioText === null || BuilderHandler.deleteInProgress)
  }

  function showAudioButton (index) {
    return vm.links[index].url.includes('LOTTIE')
  }

  function disableUploadButton (index) {
    return (vm.currentAudioText[index] === vm.links[index].audioText) ||
      BuilderHandler.disableAudioUpload === true ||
      vm.currentAudioText[index].length === 0
  }

  /**
   * error handler for all upload functions
   * @param err object error returned from api call
   * @param provider string provider that returned the error
   */
  function failure (err, provider) {
    updateNgMessages('invalidLink')
    console.error(`Failed to get ${provider} media details:`, err)
  }

  /**
   * This function handles the saving of the question after the media has
   * been added to links array
   * @param img string path to thumbnail
   * @param url string url of video
   */
  function pushAndSave (img, url) {
    // checks if link already exists in links array
    if (vm.links.some(link => link.url === url)) return
    BuilderService.addQuestionVideo({img, url}).then(
      data => {
        vm.links.push(data)
        vm.newLink = ''

        BuilderService.saveQuestion(true).then(
          data => success(),
          error => console.error('Question failed to saved:', error)
        )
      },
      error => console.error('Failed to add video link to question:', error)
    )

    /**
     * This success handler clears errors on success and shows error if limit has been reached
     */
    function success () {
      if (vm.links.length === 5) vm.mediaLinkForm.link.$setValidity('linkLimit', false)
      vm.mediaLinkForm.link.$setValidity('invalidLink', true)
      vm.mediaLinkForm.link.$setValidity('linkAlreadyExists', true)
    }
  }

  /**
   * This function displays the ng-messages errors in the DOM
   * @param error string error to be set
   */
  function updateNgMessages (error) {
    let clearError = error.indexOf('invalid') >= 0 ? 'linkAlreadyExists' : 'invalidLink'
    vm.mediaLinkForm.link.$setValidity(error, false)
    vm.mediaLinkForm.link.$setValidity(clearError, true)
  }

  /**
   * This function handles the deleting of the media links, and saves a question after
   * @param idx int index of link in array to be deleted
   * @param linkId int value passed to the BE for deletion
   */
  function deleteLink (idx, linkId) {
    if (!vm.links[idx]) return
    BuilderService.deleteQuestionVideo(linkId).then(
      data => {
        // remove the deleted media from the array
        vm.links.splice(idx, 1)
        vm.currentAudioText.splice(idx, 1)
        BuilderService.saveQuestion(true).then(
          data => {
            if (!data || data.status) failure(data)
            console.warn('vm.mediaLink ==== ', vm.mediaLinkForm)
            vm.mediaLinkForm.link.$setValidity('linkLimit', true)
          },
          error => console.error('Question failed to save:', error)
        )
      },
      error => console.error('Failed to delete question video:', error)
    )
  }

  function addMetaData (idx) {
    // get current meta data of clicked image,
    // open dialog
    $mdDialog.show({
      templateUrl: 'partials/templates/builder/meta_data_dialog.html',
      controller: builderMetaDataDialog,
      controllerAs: 'vm',
      disableParentScroll: false,
      locals: {
        mdDialog: $mdDialog,
        links: vm.links,
        linkIdx: idx,
        typeOfMedia: 'video',
        existingMetadata: vm.links[idx].metaData
      }
    })
  }
}

export { mcqsBuilderMediaLinks }
