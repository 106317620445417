function patientDeleteDialogController (index, $mdDialog, patients, BuilderService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.cancel = $mdDialog.cancel
  vm.deletePatient = deletePatient

  function deletePatient () {
    BuilderService.deletePatientData(patients[index].id)
      .then(success, failure)

    function success (data) {
      if (!data || data.status) return failure(data)
      $mdDialog.hide()
    }

    function failure (error) { console.error('Failed to delete patient ', error) }
  }
}

export { patientDeleteDialogController }
