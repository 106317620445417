'use strict'
/* global response */
/**
 * mcqs-google-login directive
 * @type {{template: string, controller: googleLoginController, controllerAs: string}}
 */
const mcqsGoogleLogin = {
  template: `
        <md-button
            class="bttn bttn__auth bttn--google"
            ng-click="vm.signIn()"
            ng-disabled="!vm.cookieService.cookieConsent"
        >Sign in with Google</md-button>
    `,
  controller: googleLoginController,
  controllerAs: 'vm'
}

/**
 * The googleLoginController exposes the GoogleService to the html
 * @param $mdDialog
 * @param $state
 * @param $rootScope
 * @param AuthenticationService
 * @param ExamService
 * @param GoogleService
 * @param ProfileService
 * @param CookieConsentService
 */
function googleLoginController ($mdDialog, $state, $rootScope, AuthenticationService, ExamService, GoogleService, ProfileService, CookieConsentService) {
  'ngInject'
  /* jshint validthis:true */
  let vm = this
  // binds to the DOM to disable facebook login button until cookies are accepted.
  vm.cookieService = CookieConsentService

  vm.signIn = signIn

  /**
   * Calls the Google service to log a user in. If successful passes the response to socialSignin.
   * If unsuccessful displays a dialog to the user showcasing the error
   */
  function signIn () {
    /* Unfortunately this timeout is needed because FF has a problem handling asynchronous tasks.
     https://github.com/angular/angular.js/issues/10083 */
    // It appears as though the firefox issue is fixed. Leaving this here until I'm 100%
    // $timeout(() => {
    GoogleService.signIn().then(
      data => {
        if (data.user.getEmail()) { return socialSignIn(data.user, data.id) }
        data.user.message = 'No email address was returned from Google. You need a valid email address to enter MCQS.'
        $state.go('root.signup', response)
      },
      error => {
        console.error('Signing in with Google failed:', error)
        $mdDialog.show($mdDialog
          .alert()
          .title('Google sign in failed')
          .disableParentScroll(false)
          .textContent(`
                            You could not be signed in with Google at this time.
                            A browser extension may be causing this problem.
                            Please check your settings and refresh before trying again.
                        `)
          .ok('Okay')
        )
      }
    )
    // })
  }

  /**
   * Passes the users details to the AuthenticationService to update their sign in details on the BE
   * then creates a session, updates their current exam and transitions them to the question page
   * @param profile object
   */
  function socialSignIn (profile, id) {
    AuthenticationService.socialSignIn({
      name: profile.getName(),
      firstName: profile.getGivenName(),
      surname: profile.getFamilyName(),
      email: profile.getEmail(),
      network: 'Google',
      socialId: profile.getId(),
      profilePic: profile.getImageUrl(),
      id
    }).then(
      data => {
        AuthenticationService.createSession(data.loginId, data.userId, data.token, data.username)
        ProfileService.setPreferences(data.preferences)
        ExamService.updateCurrentExam(data.currentExam)
        $state.go('root.auth.question')
        if (data.currentExam === null || data.currentExam === 0) {
          let examCheck = $rootScope.$on('examSelected', () => {
            checkPassword(data)
            examCheck()
          })
        } else { checkPassword(data) }
      },
      error => console.error('Social sign in with Google failed:', error)
    )
  }

  function checkPassword (data) {
    AuthenticationService.checkUserPasswordExists().then((res) => {
      if (!res.password) {
        AuthenticationService.passwordCheckDialog(data.userId, $rootScope)
      }
    }, (err) => { console.error('Error checking user password: ', err) })
  }
}

export { mcqsGoogleLogin }
