'use strict'

function patientUploadDialog (mdDialog, BuilderService, patients, index) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this
  vm.close = close
  vm.sendPatientData = sendPatientData

  if (index) {
    vm.patientData = patients[index]
    vm.patientData.dob = new Date(vm.patientData.dob)
  } else {
    vm.patientData = {}
  }
  vm.uploadError = false

  function sendPatientData() {
    if (!index) {
        BuilderService.addNewPatient(vm.patientData).then(data => {
          if (data.status === 500) {} //TODO handle error

          vm.patientData.id = data.insertId
          patients.push(vm.patientData)
          close()
        }, error => {
          console.log('err === ', error)
        })
    } else {
        BuilderService.editPatientData(vm.patientData).then(data => {
          if (data.status === 500) {} //TODO handle error
          patients[index] = vm.patientData
          close()
        }, error => {
          //TODO handle all this with toasts etc
          console.log('errror === ', error)
        })
    }
  }

  function close () {
    mdDialog.hide()
  }
}

export { patientUploadDialog }
