'use strict'
/**
 * builderService handles requests from the builder to the back end
 * it allows for creating, deleting, saving and publishing amongst other things
 * @param $http
 * @param $mdDialog
 * @param $q
 * @param DOMAIN the value of process.env.APP_ENV.trim()
 * @param BuilderHandler
 * @param $timeout
 * @returns {{addQuestionVideo: (function(*=): *), deleteQuestionVideo: (function(*): *), changeExam: (function(*, *=): angular.IPromise<any>), cloneQuestion: (function(*): *), deleteImage: (function(*): *), deleteQuestion: (function(*): *), getPreviewQuestion: (function(): *), getQuestionsArray: (function(*): *), getQuestion: (function(*): *), getRelatedQuestions: (function(*): *), newQuestion: (function(*): *), openQuestionDialog: openQuestionDialog, publishQuestion: (function(*=): angular.IPromise<any>), saveQuestion: saveQuestion}}
 * @constructor
 */
function BuilderService ($http, $mdDialog, $q, DOMAIN, ADMINAPI, BuilderHandler, $timeout) {
  'ngInject'
  let requestCounter = 0
  const service = {
    addAudio,
    addMetaData,
    addQuestionTags,
    addQuestionVideo,
    addNewTag,
    addNewPatient,
    deleteQuestionVideo,
    changeExam,
    cloneQuestion,
    deleteImage,
    deleteLinkedPuzzle,
    deleteQuestion,
    getAllPatients,
    getLinkedChessPuzzles,
    getPreviewQuestion,
    getQuestionsArray,
    getQuestion,
    getRelatedQuestions,
    getUnlinkedPuzzles,
    loadQuestionTags,
    loadTags,
    newQuestion,
    openQuestionDialog,
    publishQuestion,
    removeQuestionTags,
    removeAudio,
    saveChess,
    saveQuestion,
    setDifficulty,
    setPuzzleLink,
    editPatientData,
    deletePatientData,
    getExistingPatientLink,
    deletePatientLink,
    linkPatientQuestion
  }

  return service

  function addAudio (audioText, lottieId, questionId) {
    return $http.post(DOMAIN + '/api/builder/audio/', {
      questionId,
      audioText,
      lottieId
    }).then(saveSuccess, saveFailure)
  }

  /**
   * requests a question clone from the BE
   * @param questionId int question id to be cloned
   * @returns {{data: { 0: {title: string, items: {0: string, 1: string, 2: string, 3: string, 4: string}}, images: [{imageId: int, shortUrl: string, thumbnail: string}], links: [{$$hashKey: string, id: int, img: string, url: string}], question: {background: string, core: string, exam: {examId: int, examName: string}, explanation: string, options: {correct: [], incorrect: []}, optionsArray: [], question: string, questionId: int, relatedQuestions: int, title: string}, status: isDynamic: boolean, isPublished: boolean, messages: []}}}
   */
  function cloneQuestion (questionId) {
    return $http.post(DOMAIN + '/api/builder/question/clone/' + questionId, {
      selectedTags: BuilderHandler.selectedTags,
      chess: BuilderHandler.chess
    }).then(success, failure)
  }

  /**
   * requests the questions array from the BE
   * @param examId int exam to get questions for
   * @returns {{data: {questions: [{questionId: int, dateCreated: string, dateModified: string, dislikes: int, incorrects: int, isPublished: int, likes: int, questionId: int, score: float, title: string}]}}}
   */
  function getQuestionsArray (examId) {
    return $http.get(DOMAIN + '/api/builder/questions/' + examId).then(success, failure)
  }

  /**
   * requests a specific question from the BE
   * @param id int id for question to request
   * @returns {{data: { 0: {title: string, items: {0: string, 1: string, 2: string, 3: string, 4: string}}, images: [{imageId: int, shortUrl: string, thumbnail: string}], links: [{$$hashKey: string, id: int, img: string, url: string}], question: {background: string, core: string, exam: {examId: int, examName: string}, explanation: string, options: {correct: [], incorrect: []}, optionsArray: [], question: string, questionId: int, relatedQuestions: int, title: string}, status: isDynamic: boolean, isPublished: boolean, messages: []}}}
   */
  function getQuestion (id) {
    return $http.get(DOMAIN + '/api/builder/question/' + id).then(success, failure)
  }

  /**
   * requests a new question from the BE
   * @param examId int which exam to make the new question in
   * @returns {{data: {items: {0: string, 1: string, 2: string, 3: string, 4: string}, title: string}, images: [], question: {background: string, core: string, exam: {examId: int, examName: string}, explanation: string, options: {correct: [], incorrect: []}, optionsArray: [], question: string, questionId: int, relatedQuestions: int, title: string}, status: {dynamic: boolean, isDynamic: boolean, isPublished: boolean, isValid: boolean, messages: []}}}
   */
  function newQuestion (examId) {
    return $http.get(DOMAIN + '/api/builder/question/new/' + examId).then(success, failure)
  }

  /**
   * gets questions that are related from the BE
   * @param questionId int question id to get relations of
   * @returns {{data: [{questionId: int, dateCreated: string, dateModified: string, dislikes: int, incorrects: int, isPublished: int, likes: int, questionId: int, score: float, title: string}]}}
   */
  function getRelatedQuestions (questionId) {
    return $http.get(DOMAIN + '/api/builder/question/related/' + questionId).then(success, failure)
  }

  /**
   * tells the BE to publish/unpublish a question
   * @param isPublished boolean
   * @returns {angular.IPromise<{data: 'OK'}>}
   */
  function publishQuestion (isPublished) {
    let questionId = BuilderHandler.question.questionId
    return $http.put(DOMAIN + '/api/builder/question/publish/' + questionId, {
      isPublished: isPublished
    }).then(success, failure)
  }

  /**
   * tells the BE to change a users exam
   * @param questionId int current question id
   * @param examId int exam to change to
   * @returns {angular.IPromise<{data: [{questionId: int, dateCreated: string, dateModified: string, dislikes: int, incorrects: int, isPublished: int, likes: int, questionId: int, score: float, title: string}]}>}
   */
  function changeExam (questionId, examId) {
    return $http.put(DOMAIN + '/api/builder/question/exam/' + questionId, {
      'examId': examId
    }).then(success, failure)
  }

  /**
   * deletes the selected question
   * @param id int id of question to be deleted
   * @returns {{data: 'OK'}}
   */
  function deleteQuestion (id) {
    return $http.delete(DOMAIN + '/api/builder/question/' + id).then(success, failure)
  }

  /**
   * tells the BE to delete a specific image
   * @param id id of image to be deleted
   * @returns {{data: 'OK'}}
   */
  function deleteImage (id) {
    return $http.delete(DOMAIN + '/api/builder/question/image/' + id).then(success, failure)
  }

  /**
   * shows a dialog to the user which allows them to open specific questions
   * in the builder
   * @param locals {{closeable: boolean, currentExam: int, currentQuestionId: string}}
   */
  function showQuestionDialog (locals) {
    const defaultLocals = {
      closeable: false,
      currentExam: null,
      currentQuestionId: null
    }
    $mdDialog.show({
      templateUrl: 'partials/templates/builder/question_dialog.html',
      controller: 'builderQuestionDialogController',
      controllerAs: 'vm',
      parent: angular.element(document.body),
      locals: angular.extend({}, defaultLocals, locals || {}),
      escapeToClose: false,
      disableParentScroll: false,
      fullscreen: true
    })
  }

  /**
   * sends a link object to the BE to be uploaded
   * @param linkObj {{img: string, url: string}}
   * @returns {{data: $$hashKey: string, id: int, img: string, url: string}}
   */
  function addQuestionVideo (linkObj) {
    // initialised as const to ensure upload is to correct question
    const {questionId} = BuilderHandler.question
    return $http.post(`${DOMAIN}/api/builder/question/video/${questionId}`, linkObj).then(success, failure)
  }

  /**
   * deletes a video from a question
   * @param linkId int link to be deleted
   * @returns {{data: 'OK'}}
   */
  function deleteQuestionVideo (linkId) {
    // initialised as const to ensure upload is to correct question
    const {questionId} = BuilderHandler.question
    return $http.delete(`${DOMAIN}/api/builder/question/video?q=${questionId}&v=${linkId}`).then(success, failure)
  }

  /**
   * opens the question list dialog
   * @param locals {{closeable: boolean, currentExam: int, currentQuestionId: string}}
   */
  function openQuestionDialog (locals) {
    if (BuilderHandler.isQuestionValid()) {
      showQuestionDialog(locals)
    } else {
      BuilderHandler.showWarning().then(
        function warningSuccess () {
          showQuestionDialog(locals)
        }
      )
    }
  }

  function addNewTag (tagName, questionId) {
    return $http.post(DOMAIN + '/api/tags/add/new', {tagName: tagName, questionId: questionId})
      .then(success, failure)
  }

  /**
   * adds a tag to a question
   * @param userId - used to ensure user is author
   * @param tag - Object - tag object containing id of tag to be added
   * @param questionId - id of question to add tag to
   * @returns {*}
   */
  function addQuestionTags (userId, tag, questionId) {
    return $http.post(DOMAIN + '/api/tags/add', {userId, tag: tag['tag_combination_id'], questionId})
      .then(success, failure)
  }

  function removeAudio (questionId, lottieId) {
    return $http.post(DOMAIN + '/api/builder/audio/delete', {
      questionId, lottieId
    }).then(saveSuccess, saveFailure)
  }

  /**
   * removes a tag from a question
   * @param userId - used to ensure user is author
   * @param tag - Object - tag object containing id of tag to be removed
   * @param questionId - id of question to add tag to
   * @returns {*}
   */
  function removeQuestionTags (userId, tag, questionId) {
    return $http.delete(DOMAIN + `/api/tags/remove/${userId}/${tag['tag_combination_id']}/${questionId}`)
      .then(success, failure)
  }

  /**
   * loads all related tags for a specific question
   * @param questionId
   * @returns {*}
   */
  function loadQuestionTags (questionId) {
    return $http.get(DOMAIN + `/api/tags/all/${questionId}`).then(success, failure)
  }

  /**
   * loads all tags for an exam
   * @param examId
   * @returns {*}
   */
  function loadTags (examId) {
    return $http.post(DOMAIN + '/api/tags/builder', {examId}).then(success, failure)
  }

  /**
   * saves current chess position for question
   * @param fen
   * @param orientation
   * @returns {*}
   */
  function saveChess (fen, orientation) {
    return $http.put(DOMAIN + '/api/builder/chess/save', {
      fen,
      orientation,
      questionId: BuilderHandler.question.questionId
    }).then(success, failure)
  }

  /**
   * saves the current question
   * @param override boolean used when uploading a media link to force a save
   * @returns {*}
   */
  function saveQuestion (override) {
    // Increment the counter so there is at least 1 request
    requestCounter++
    if (requestCounter === 1) {
      // if question has id, is different to last save and has not been overridden
      // override is passed when media links are updated to force save
      if (BuilderHandler.question.questionId && (BuilderHandler.isDifferent() || override)) {
        let question = angular.copy(BuilderHandler.question)
        question.fen = BuilderHandler.chess.fen
        let questionData = angular.copy(BuilderHandler.data)
        let questionToSend = angular.merge({}, BuilderHandler.emptyQuestion, question)
        $timeout(() => BuilderHandler.setLastSave())
        return $http.put(DOMAIN + '/api/builder/question', {
          question: questionToSend,
          data: questionData
        }).then(saveSuccess, saveFailure)
      } else {
        requestCounter = 0
        return $q(rejectSame)
      }
    } else {
      return $q(rejectSave)
    }

    /**
     * rejects when no changes made
     * @param resolve promise resolve
     * @param reject promise reject
     */
    function rejectSame (resolve, reject) { reject('No differences to save') }

    /**
     * rejects when save in progress
     * @param resolve promise resolve
     * @param reject promise reject
     */
    function rejectSave (resolve, reject) {
      BuilderHandler.showToast('Save already in progress')
      reject('Save already in progress')
    }
  }

  function deletePatientLink(questionId) {
    return $http.post(DOMAIN + '/napi/delete/patient/link', {
      questionId
    }).then(success, failure)
  }

  /**
   * gets the preview question from the BE
   * @returns {{data: {background: string, core: string, correctAnswer: int, explanation: string, optionsArray: string[], question: string}}}
   */
  function getPreviewQuestion () {
    let question = angular.copy(BuilderHandler.question)
    let questionData = angular.copy(BuilderHandler.data)
    let questionToSend = angular.merge({}, BuilderHandler.emptyQuestion, question)
    return $http.post(DOMAIN + '/api/builder/preview', {
      question: questionToSend,
      data: questionData
    }).then(success, failure)
  }

  function getExistingPatientLink (questionId) {
    return $http.post(DOMAIN + '/napi/get/linked/patient', {
      questionId
    }).then(success, failure)
  }

  /**
   * success handler for Builder Service
   * @param response object returned from BE
   * @returns {*|boolean} either th response.data object or the full response if error
   */
  function success (response) {
    // Update the 'last' state of the question
    BuilderHandler.setLastSave()
    return (response.status !== 200) ? response : response.data || true
  }

  /**
   * Error handler for Builder Service
   * @param error object returned from server
   * @returns {*} object error object
   */
  function failure (error) { return error }

  /**
   * save success handler, sets the current question status and triggers saveQuestion()
   * @param response
   */
  function saveSuccess (response) {
    if (response.status === 200) {
      BuilderHandler.setStatus(response.data)
      // If there are pending requests, perform an additional save.
      if (requestCounter > 1) {
        requestCounter = 0
        service.saveQuestion()
      }
      requestCounter = 0
      BuilderHandler.showToast('Saved successfully!')
      return $q.resolve(response.data || true)
    } else {
      requestCounter = 0
      return $q.resolve(response)
    }
  }

  /**
   * save error handler, clears, last save, resets the counter and shows error toast
   * @param error object returned from BE
   * @returns {*}
   */
  function saveFailure (error) {
    BuilderHandler.clearLastSave()
    requestCounter = 0
    error.status === 700
      ? BuilderHandler.showToast('Invalid SSML in audio', error)
      : BuilderHandler.showToast('An error occurred while saving...', error)
    return $q.reject(error)
  }

  function getAllPatients () {
    return $http.get(`${DOMAIN}/napi/get/patients`)
      .then(success, failure)
  }

  function addNewPatient (patientData) {
    console.log('asdasdasd === ', patientData)
    return $http.post(`${DOMAIN}/napi/set/patient`, patientData)
      .then(success, failure)
  }

  function editPatientData (patientData) {
    return $http.post(`${DOMAIN}/napi/update/patient`, patientData)
      .then(success, failure)
  }

  function deletePatientData (id) {
    return $http.post(`${DOMAIN}/napi/delete/patient`, {id})
      .then(success, failure)
  }

  function getLinkedChessPuzzles () {
    return $http.get(`${DOMAIN}/api/chess/specific/puzzle/${BuilderHandler.question.questionId}`)
      .then(success, failure)
  }

  function deleteLinkedPuzzle (puzzleId, nextPuzzleId) {
    return $http.delete(`${DOMAIN}/api/chess/related/puzzle/${puzzleId}/${nextPuzzleId}`).then(success, failure)
  }

  function getUnlinkedPuzzles (examId) {
    return $http.post(`${DOMAIN}/api/chess/unlinked/puzzles`, {
      examId,
      questionId: BuilderHandler.question.questionId
    }).then(success, failure)
  }

  function setDifficulty (diff) {
    return $http.post(`${DOMAIN}/api/set/difficulty`, {
      diff,
      questionId: BuilderHandler.question.questionId
    }).then(success, failure)
  }

  function setPuzzleLink (questionId, linkedQuestionId) {
    return $http.post(`${DOMAIN}/api/chess/puzzle/linked`, {
      puzzleId: questionId,
      nextPuzzleId: linkedQuestionId
    }).then(success, failure)
  }

  function addMetaData (metaData) {
    return $http.post(`${DOMAIN}/api/builder/question/metadata/save`, metaData)
      .then(success, failure)
  }

  function linkPatientQuestion (patientId, questionId) {
    return $http.post(DOMAIN + '/napi/set/patient/link', {
      patientId,
      questionId
    }).then(success, failure)
  }
}

export { BuilderService }
