'use strict'
/* global gapi */
// https://developers.google.com/identity/sign-in/web/quick-migration-guide#migrate_an_html_sign-in_button

/**
 * The googleService handles the functionality that makes requests to the facebook api.
 * First it authenticates a user with google and then, if successful, signs the user in
 * @param $timeout
 * @returns {{signIn: (function(): *), signOut: signOut}}
 * @constructor
 */
function GoogleService ($timeout) {
  'ngInject'
  let auth2

  /**
   * checks for the existence of google api and if it exists, initialises it
   */
  function checkGAPI () {
    // Simple check for google api.
    $timeout(() => {
      // gapi needs about a second to init
      !window.gapi || !gapi || gapi === undefined ? console.error('GAPI not found.') : gapi.load('auth2', () => {
        // Retrieve the singleton for the GoogleAuth library and set up the client.
        auth2 = gapi.auth2.init({
          client_id: '1017498637926-vjseuj54f6q8e97cm01o1bls04a8ilga.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email'
        })
      })
    }, 1000)
  }

  checkGAPI()

  return {
    signIn: signIn,
    signOut: signOut
  }

  /**
   * signs a user in using the auth2 object and returns the google users profile
   * or returns an error
   * @returns {*}
   */
  function signIn () {
    // timeout is required to give the app time to attach the gapi tag to the DOM
    $timeout(checkGAPI())
    // CheckGAPI is not creating GAPI fast enough for gapi.auth to sign in
    return $timeout(() => {
      let noAPI = (typeof gapi === 'undefined' || !gapi || !gapi.auth2 || !gapi.auth2.hasOwnProperty('getAuthInstance'))
      if (noAPI) return new Promise((resolve, reject) => reject(new Error('No Google API instance found. Could not sign in with Google API.')))
      auth2 = gapi.auth2.getAuthInstance()
      return auth2.signIn().then(
        googleUser => {
          return {
            user: googleUser.getBasicProfile(),
            id: googleUser.getAuthResponse().id_token
          }
        },
        error => {
          return error
        }
      )
    }, 1000)
  }

  /**
   * Signs a user out using the auth2 object
   */
  function signOut () {
    let noAPI = (typeof gapi === 'undefined' || !gapi || !gapi.auth2 || !gapi.auth2.hasOwnProperty('getAuthInstance'))
    if (noAPI) return console.error('No Google API instance found. Could not sign out with Google API.')
    auth2 = gapi.auth2.getAuthInstance()
    auth2.signOut().then(() => console.log('Google sign out called successfully.'))
  }
}
export { GoogleService }
