'use strict'

import {patientUploadDialog} from "./patient-upload-dialog";
import {patientDeleteDialogController} from "./patient-delete-dialog";

const mcqsBuilderPatientsLink = {
  templateUrl: 'partials/templates/builder/builder_patients_link.html',
  controller: builderLinkedPatientsController,
  controllerAs: 'vm'
}

/**
 * builderQuestionListController is used to attach the bindings to the vm, and then question_list.html
 */
function builderLinkedPatientsController ($cookies, BuilderService, $timeout, BuilderHandler, TourBuilderHandler, $scope, $state, $mdDialog) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this // eslint-disable-line no-unused-vars

  vm.addPatientData = addPatientData
  vm.editPatientData = editPatientData
  vm.deletePatientData = deletePatientData
  vm.linkPatientQuestion = linkPatientQuestion
  vm.deletePatientLink = deletePatientLink

  BuilderService.getExistingPatientLink(BuilderHandler.question.questionId)
    .then((link) => {
      if (!link.data) {
        updatePatients()
        vm.linked = false
      } else {
        // TODO show link here
        vm.linked = true
        vm.patients = link.data
      }
    }, (err) => {
      console.log('err === ', err)
    })

  function updatePatients () {
    BuilderService.getAllPatients().then(data => {
      vm.patients = data
      vm.patients.forEach((patient, idx) => {
        vm.patients[idx].dob = new Date(vm.patients[idx].dob)
      })
    }, error => {
      console.log('error === ', error)
    })
  }

  function deletePatientLink () {
    BuilderService.deletePatientLink(BuilderHandler.question.questionId)
      .then(() => {
        updatePatients()
        vm.linked = false
      }, err => {
        console.log('err === ', err)
      })
  }

  function addPatientData () {
    $mdDialog.show({
      templateUrl: 'partials/templates/builder/patient-data-dialog.html',
      controller: patientUploadDialog,
      controllerAs: 'vm',
      disableParentScroll: false,
      locals: {
        mdDialog: $mdDialog,
        patients: vm.patients,
        index: null
      }
    })
  }

  function editPatientData (idx) {
    $mdDialog.show({
      templateUrl: 'partials/templates/builder/patient-data-dialog.html',
      controller: patientUploadDialog,
      controllerAs: 'vm',
      disableParentScroll: false,
      locals: {
        mdDialog: $mdDialog,
        patients: vm.patients,
        index: idx
      }
    })
  }

  function deletePatientData (idx) {
    let dialogPromise = $mdDialog.show({
      templateUrl: 'partials/templates/builder/patient-delete-dialog.html',
      controller: patientDeleteDialogController,
      controllerAs: 'vm',
      bindToController: true,
      disableParentScroll: false,
      locals: {
        mdDialog: $mdDialog,
        patients: vm.patients,
        index: idx
      }
    })

    dialogPromise.then(() => {
      updatePatients()
    })
  }

  function linkPatientQuestion (index) {
    BuilderService.linkPatientQuestion(
      vm.patients[index].id,
      BuilderHandler.question.questionId
    ).then(data => {
      vm.patients = [vm.patients[index]]
      vm.linked = true
    }, err => {
      console.log('err === ', err)
    })
  }
}

export { mcqsBuilderPatientsLink }
