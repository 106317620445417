'use strict'

/**
 * controller for landing about us page
 * @param $window
 * @param $scope
 */
function aboutUsController ($window, $scope) {
  'ngInject'
  /* jshint validthis:true */

  const body = angular.element(document.getElementsByTagName('body'))
  const landingFooter = document.getElementsByClassName('landing-footer')
  const techHeading = document.getElementsByClassName('tech-heading')
  const angularTech = document.getElementsByClassName('angular-tech')
  const nodeTech = document.getElementsByClassName('node-tech')
  const flutterTech = document.getElementsByClassName('flutter-tech')
  const lottieTech = document.getElementsByClassName('lottie-tech')
  const awsTech = document.getElementsByClassName('aws-tech')
  const mathjaxTech = document.getElementsByClassName('mathjax-tech')
  const zoomTech = document.getElementsByClassName('zoom-tech')
  const youtubeTech = document.getElementsByClassName('youtube-tech')

  angular.element(body[0]).on('scroll', () => {
    if (isVisible(techHeading, 'landing__instant-slide-in')) {
      attachSlideIn(techHeading[0])
    }
    if (isVisible(angularTech, 'landing__instant-slide-in')) {
      attachSlideIn(angularTech[0])
    }
    if (isVisible(nodeTech, 'landing__instant-slide-in-1-sec-delay')) {
      attachSlideInDelay(nodeTech[0])
    }
    if (isVisible(flutterTech, 'landing__instant-slide-in')) {
      attachSlideIn(flutterTech[0])
    }
    if (isVisible(lottieTech, 'landing__instant-slide-in-1-sec-delay')) {
      attachSlideInDelay(lottieTech[0])
    }
    if (isVisible(awsTech, 'landing__instant-slide-in')) {
      attachSlideIn(awsTech[0])
    }
    if (isVisible(mathjaxTech, 'landing__instant-slide-in-1-sec-delay')) {
      attachSlideInDelay(mathjaxTech[0])
    }
    if (isVisible(zoomTech, 'landing__instant-slide-in')) {
      attachSlideIn(zoomTech[0])
    }
    if (isVisible(youtubeTech, 'landing__instant-slide-in-1-sec-delay')) {
      attachSlideInDelay(youtubeTech[0])
    }
    if (isVisible(landingFooter, 'landing__instant-slide-in')) {
      attachSlideIn(landingFooter[0])
    }
  })

  $scope.$on('$destroy', () => {
    angular.element(body[0]).off('scroll')
  })

  /**
   * attches slide in class to element
   * @param element
   */
  function attachSlideIn (element) {
    angular.element(element).addClass('landing__instant-slide-in')
  }

  /**
   * attaches slide in delay class to element
   * @param element
   */
  function attachSlideInDelay (element) {
    angular.element(element).addClass('landing__instant-slide-in-1-sec-delay')
  }

  /**
   * checks if the element is visible on screen
   * @param element
   * @param cssClass
   * @returns {boolean|boolean}
   */
  function isVisible (element, cssClass) {
    return element[0].getBoundingClientRect().top <= $window.innerHeight &&
      !element[0].classList.contains(cssClass)
  }
}

export { aboutUsController }
